/**
 * Sets meta tags for the page, including Open Graph and Twitter Card tags.
 *
 * @param {string} description - The description to be used in the meta tags.
 *
 * @example
 * useMetaTags('This is a sample description for the page.');
 *
 * The function sets the following meta tags:
 * - title: string
 * - description: string
 * - og:title: opengraph/facebook
 * - og:description: opengraph/facebook
 * - og:image: opengraph/facebook
 * - og:url: opengraph/facebook
 * - twitter:card: twitter
 * - twitter:title: twitter
 * - twitter:description: twitter
 * - twitter:image: twitter
 */
export const useMetaTags = (description: string) => {
  const runtimeConfig = useRuntimeConfig()
  const { fullPath } = useRoute()

  // temporary image
  const imageUrl = '/share-icon.jpg'

  const title = 'Home Boligoverblik'
  useHead({
    title,
    meta: [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      {
        property: 'og:image',
        content: `${runtimeConfig.public.BASE_URL}${imageUrl}`,
      },
      {
        property: 'og:url',
        content: `${runtimeConfig.public.BASE_URL}${fullPath}`,
      },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:title', content: title },
      { property: 'twitter:description', content: description },
      {
        property: 'twitter:image',
        content: `${runtimeConfig.public.BASE_URL}${imageUrl}`,
      },
    ],
  })
}
